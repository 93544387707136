@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
/* @import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination"; */

@font-face {
  font-family: rabar;
  src: url("./assets/fonts/Rabar_021.ttf");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "roboto", serif;
  background-color: #f2f2f2;
  overflow-x: hidden;
}

body[dir="rtl"] {
  font-family: rabar;
}

/* customize the browser defaults */
::selection {
  background-color: #080808a6;
  color: #ffffff;
}

::-webkit-scrollbar {
  background-color: #ffffff;
  width: 7px;
  border-radius: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #a09999;
  width: 7px;
  border-radius: 8px;
}

.container {
  @apply max-w-[1340px] w-full relative xl:px-0 px-3;
}

.section {
  @apply w-full min-h-[100vh] flex items-start justify-center;
}

.flex-center {
  @apply flex justify-center items-center;
}

.flex-start {
  @apply flex justify-start items-start;
}

.link-hover {
  @apply duration-300 hover:text-primary;
}

.primary-button {
  @apply bg-primary text-white sm:px-7 sm:py-3 px-4 py-2 sm:text-base text-sm rounded-md;
}

.admin-section {
  @apply xl:flex-[0.8] flex-1 mt-28 mb-10 w-full;
}

.change-font {
  font-family: "roboto", serif;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #ffffff3d !important;
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 28px !important;
  color: #ffffff;
}
input[type="search"]::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
}
